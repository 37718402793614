import { getRegistryFeatureState } from '@app/registry/state/registry.selector';
import { createSelector } from '@ngrx/store';

const getImageCreationState = createSelector(
  getRegistryFeatureState,
  (state) => state.imageCreation.imageCreation,
);

export const getIsCreatingImage = createSelector(
  getImageCreationState,
  (state) => state.isLoading,
);
