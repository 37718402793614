<div class="scan-controls mb-2">
  <select
    class="col-10 form-select"
    [(ngModel)]="selectedSource"
    [disabled]="sources && sources.length <= 1">
    <option
      *ngFor="
        let source of sources;
        trackBy: trackScannerByIndex;
        let i = index
      "
      [value]="i">
      {{ source }}
    </option>
  </select>
  <button
    [disabled]="selectedSource === 0"
    class="btn btn-primary"
    (click)="scan()">
    Scan
  </button>
</div>
<div [id]="containerId"></div>
<div class="scan-controls mt-2">
  <button
    [disabled]="!uploadEnabled"
    class="btn btn-secondary"
    (click)="clear()">
    Clear
  </button>
  <button
    [disabled]="!uploadEnabled || uploading"
    class="btn btn-primary"
    (click)="upload()">
    {{ uploading ? 'Uploading...' : 'Upload' }}
  </button>
</div>
