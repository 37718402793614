import { createAction, props } from '@ngrx/store';

export const createImage = createAction(
  '[Image Creation] Create image',
  props<{
    file: ArrayBuffer | SharedArrayBuffer;
    imageType: string;
  }>(),
);

export const createImageSuccess = createAction(
  '[Image Creation] Create image success',
  props<{ imageNumber: string }>(),
);
export const createImageFailure = createAction(
  '[Image Creation] Create image failure',
  props<{ error: string }>(),
);
