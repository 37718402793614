import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ImageCreationFacade } from '@app/registry/images/state/image-creation/image-creation.facade';
import { FileUploader } from 'ng2-file-upload';
import {
  FileType,
  filterFileListForFileType,
  getAcceptedFileExtensions,
} from '@app/image-viewer/models/file-type';
import { AutoUnsubscription } from '@app/shared/helpers/observable-helpers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent extends AutoUnsubscription implements OnInit {
  public uploader: FileUploader = new FileUploader({
    allowedMimeType: ['application/pdf', 'image/tiff'],
    url: '',
  });
  selectedFile: File;
  acceptedFileTypes: FileType[];
  private _fileReader: FileReader;
  private _imageBytes: ArrayBuffer | SharedArrayBuffer;
  doneLoading: boolean;
  acceptedFileTypes$ = this.imageCreationFacade.acceptedFileTypes$;
  isCreating$ = this.imageCreationFacade.isCreating$;

  @ViewChild('fileInput', { static: true })
  fileInput: ElementRef;

  constructor(private imageCreationFacade: ImageCreationFacade) {
    super();
  }

  ngOnInit(): void {
    this._fileReader = new FileReader();

    this._fileReader.onloadstart = (event: ProgressEvent) => {
      this.doneLoading = false;
      this._imageBytes = null;
    };
    this.acceptedFileTypes$
      .pipe(takeUntil(this.notifier))
      .subscribe((fileTypes: FileType[]) => {
        if (fileTypes) {
          this.acceptedFileTypes = fileTypes;
        }
      });

    this._fileReader.onloadend = (event: any) => {
      this._imageBytes = event.target.result.split(',')[1];
      this.doneLoading = true;
    };
  }

  setSelectedFile(event: FileList) {
    const filteredFiles = filterFileListForFileType(
      event,
      this.acceptedFileTypes,
    );
    if (filteredFiles.length > 0) {
      this.selectedFile = filteredFiles[0];
      this._fileReader.readAsDataURL(this.selectedFile);
    }
  }

  clearSelection() {
    this.selectedFile = null;
  }

  openFileDialog() {
    this.fileInput.nativeElement.click();
  }

  upload() {
    if (!this.doneLoading) {
      return;
    }

    this.imageCreationFacade.createImage(
      this._imageBytes,
      this.selectedFile.type,
    );
  }

  getAcceptedExtensions() {
    return getAcceptedFileExtensions(this.acceptedFileTypes);
  }
}
