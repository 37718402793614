import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as imageViewerActions from '@app/image-viewer/state/image-viewer.actions';
import * as imageViewerSelectors from '@app/image-viewer/state/image-viewer.selectors';
import { ImagesState } from '../images/images.state';
import { createImage } from './image-creation.actions';
import { getIsCreatingImage } from './image-creation.selectors';

@Injectable({
  providedIn: 'root',
})
export class ImageCreationFacade {
  isCreating$ = this.store$.select(getIsCreatingImage);
  acceptedFileTypes$ = this.store$.select(
    imageViewerSelectors.getAcceptedFileTypes,
  );

  constructor(private store$: Store<ImagesState>) {
    this.getAcceptedFileTypes();
  }

  getAcceptedFileTypes() {
    this.store$.dispatch(imageViewerActions.getAcceptedFileTypes());
  }
  createImage(file: any, imageType: string) {
    this.store$.dispatch(createImage({ file, imageType }));
  }
}
