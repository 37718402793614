<app-modal title="Scan Image">
  <app-image-scan
    [sources]="sources"
    [containerId]="dynamsoftContainerId"
    [uploading]="isCreating$ | async"
    [uploadEnabled]="imageScanned"
    (clearClicked)="clear()"
    (scanClicked)="acquireImage($event)"
    (uploadClicked)="upload()"
  ></app-image-scan>
</app-modal>
