import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-image-scan',
  templateUrl: './image-scan.component.html',
  styleUrls: ['./image-scan.component.scss'],
})
export class ImageScanComponent implements OnInit {
  @Input() uploadEnabled: boolean;
  @Input() uploading: boolean;
  @Input() sources: string[] = [];
  @Input() containerId: string;

  @Output() scanClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() uploadClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearClicked: EventEmitter<any> = new EventEmitter<any>();

  selectedSource = 0;

  constructor() {}

  ngOnInit() {}

  scan() {
    if (this.selectedSource <= 0) {
      return;
    }

    this.scanClicked.emit(this.selectedSource - 1);
  }

  upload() {
    if (!this.uploadEnabled) {
      return;
    }

    this.uploadClicked.emit(true);
  }

  clear() {
    this.clearClicked.emit(true);
  }

  trackScannerByIndex(index: any, item: any) {
    return index; // or item.id
  }
}
