import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { createAscendingArray } from '@app/core/helpers/array-helpers';
import { convertBase64ToArrayBuffer } from '@app/core/helpers/convert-to-array-buffer';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { AppInsightsService } from '@app/core/services/app-insights.service';
import { ToastService } from '@app/core/services/toast.service';
import { ImageCreationFacade } from '@app/registry/images/state/image-creation/image-creation.facade';
import { Guid } from '@app/registry/images/state/utils/guid';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';

@Component({
  selector: 'app-dynamsoft-wrapper',
  templateUrl: './dynamsoft-wrapper.component.html',
  styleUrls: ['./dynamsoft-wrapper.component.scss'],
  providers: [],
})
export class DynamsoftWrapperComponent implements AfterViewInit, OnDestroy {
  private webTwainOnPostLoadEvent = 'OnPostLoad';

  dynamsoftReady: boolean;
  imageScanned: boolean;
  isCreating$ = this.imageCreationFacade.isCreating$;

  dynamsoftContainerId = 'dwtcontrolContainer' + Guid.newGuid();
  sources: string[] = [];

  dwObject: WebTwain;
  webTwainEnv = Dynamsoft.DWT;

  showLogging = !this.appConfig.config.applicationInsights.disableTelemetry;

  constructor(
    private imageCreationFacade: ImageCreationFacade,
    private toastService: ToastService,
    private appConfig: AppConfigService,
    private appInsights: AppInsightsService,
  ) {
    Dynamsoft.DWT.OnWebTwainReady = () => {
      this.dwObject = Dynamsoft.DWT.GetWebTwain(this.dynamsoftContainerId);
      this.dynamsoftOnReady();
    };
  }

  ngAfterViewInit(): void {
    this.sources.push('Select an option');
    this.webTwainEnv.AutoLoad = false;

    this.webTwainEnv.Containers = [
      {
        ['ContainerId']: this.dynamsoftContainerId,
        ['Width']: '100%',
        ['Height']: '500px',
      },
    ];

    this.webTwainEnv.ProductKey = this.appConfig.config.dynamsoft.key;

    this.webTwainEnv.ResourcesPath =
      this.appConfig.config.dynamsoft.resourcePath;
    this.webTwainEnv.Load();
  }

  ngOnDestroy(): void {
    if (this.webTwainEnv) {
      this.webTwainEnv.Unload();
    }
  }

  dynamsoftOnReady() {
    this.dwObject.RegisterEvent(this.webTwainOnPostLoadEvent, () => {
      this.imageScanned = true;
    });

    this.dwObject.BackgroundColor = 0xf5f5f5;
    this.dwObject.AllowMultiSelect = false;
    this.dwObject.ShowPageNumber = true;

    setTimeout(() => {
      for (let i = 0; i < this.dwObject.SourceCount; i++) {
        this.sources.push(this.dwObject.GetSourceNameItems(i));
      }
    }, 0);

    if (this.showLogging) {
      this.appInsights.log('[Dynamsoft] loaded', null);
    }
  }

  private uploadImage(result: Blob) {
    const fileReader = new FileReader();
    fileReader.onloadend = (event: any) => {
      const arrayBuffer = event.target.result.split(',')[1];
      this.imageCreationFacade.createImage(arrayBuffer, result.type);
      if (this.showLogging) {
        this.appInsights.log(
          '[Dynamsoft] image uploaded to Bokamoso API',
          null,
        );
      }
    };
    fileReader.readAsDataURL(result);
  }

  private onAcquireImageSuccess() {
    if (this.dwObject.HowManyImagesInBuffer > 0) {
      this.imageScanned = true;
    }

    this.dwObject.CloseSource();
  }

  acquireImage(sourceIndex: number) {
    if (
      this.dwObject.SourceCount <= 0 &&
      !this.dwObject.SelectSourceByIndex(sourceIndex)
    ) {
      alert('No Source Available!');
      return;
    }

    this.dwObject.OpenSource();
    this.dwObject.AcquireImage(
      {},
      () => this.onAcquireImageSuccess(),
      () => {
        this.toastService.showError('Failed to scan image.');
      },
    );

    if (this.showLogging) {
      this.appInsights.log('[Dynamsoft] image scanned', null);
    }
  }

  upload() {
    if (!this.imageScanned) {
      return;
    }

    this.dwObject.ConvertToBase64(
      createAscendingArray(this.dwObject.HowManyImagesInBuffer),
      Dynamsoft.DWT.EnumDWT_ImageType.IT_TIF,
      (result: any) =>
        this.uploadImage(
          this.convertBase64ToBlob(result.getData(0, result.getLength())),
        ),
      (errorCode: number, errorString: string) =>
        this.toastService.showError('Failed to scan image.'),
    );
  }

  clear() {
    this.imageScanned = false;
    this.dwObject.RemoveAllImages();
  }

  convertBase64ToBlob(data: string, contentType = 'image/tiff'): Blob {
    const blob = new Blob([convertBase64ToArrayBuffer(data)], {
      type: contentType,
    });
    return blob;
  }
}
