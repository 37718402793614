<app-modal title="Upload Image">
  <div
    (click)="openFileDialog()"
    (onFileDrop)="setSelectedFile($event)"
    *ngIf="!selectedFile"
    [uploader]="uploader"
    class="card card-body bg-light drop-zone p-4 mb-1"
    ng2FileDrop
  >
    Drop file here or Click to Select Image
  </div>

  <div class="mt-3">
    <button (click)="openFileDialog()" class="btn btn-secondary">
      Browse
    </button>

    <span class="ms-2">{{
      selectedFile ? selectedFile.name : 'No image selected'
      }}</span>
  </div>

  <input
    #fileInput
    (onFileSelected)="setSelectedFile($event)"
    [accept]="getAcceptedExtensions()"
    [uploader]="uploader"
    class="d-none mb-3"
    ng2FileSelect
    type="file"
  />

  <div class="row mt-5">
    <div class="col">
      <button
        (click)="upload()"
        [disabled]="!selectedFile || !doneLoading || (isCreating$ | async)"
        class="btn btn-primary float-end ms-1"
      >
        {{ (isCreating$ | async) ? 'Uploading...' : 'Upload' }}
      </button>
      <button
        (click)="clearSelection()"
        *ngIf="selectedFile"
        class="ms-1 btn btn-secondary float-end"
      >
        Clear
      </button>
    </div>
  </div>
</app-modal>
